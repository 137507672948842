import React, { useState } from "react"
import { Row, Col, Card, CardBody, CardFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap" 
import {
    useLocation,
    useNavigate,
    useParams,
    Link
} from "react-router-dom";
import PropTypes from 'prop-types';
 
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { withTranslation } from "react-i18next";
import icon_wallet from "../../assets/images/icon_wallet.png"

const CardUser = (props) => {
  const { data } = props;
  let navigate = useNavigate();

  const [menu, setMenu] = useState(false)

  const goto = (path) => {
    navigate("/"+path)
  }
  return (
    <React.Fragment>  
        <Row>
          <Col xs="6" sm="6" md="3"> 
            <Card className="wallet-box">
            <CardBody> 
              <div className="wallet-header">  
                  <h5>{props.t("total_reg")}</h5>
                  <div className="wallet-icon">
                    <img src={icon_wallet}  alt="wallet-icon" width={40}/>
                  </div> 
              </div>
              <div className="wallet-info">  
                <h1>{data.total_reg}</h1>  
              </div>
              <div className="wallet-action">  
                <div className="btn-group">  
                  <span onClick={() => goto("deposit")}  className="btn btn-primary btn-sm">
                  {props.t("Deposit")}
                  </span>{" "}
                  <span onClick={() => goto('transfer')} className="btn btn-light btn-sm">
                  {props.t("Transfer")}
                  </span>
                </div>
              </div>

            </CardBody> 
            </Card>
          </Col>

          <Col xs="6" sm="6" md="3"> 
            <Card className="wallet-box">
            <CardBody> 
              <div className="wallet-header">  
                  <h5>{props.t("total_cash")}</h5>
                  <div className="wallet-icon">
                    <img src={icon_wallet}  alt="wallet-icon" width={40}/>
                  </div> 
              </div>
              <div className="wallet-info">  
                <h1>{data.total_cash}</h1>  
              </div>
              <div className="wallet-action">  
                <div className="btn-group">   
                  <span onClick={() => goto("withdrawal")}  className="btn btn-primary  btn-sm">
                  {props.t("Withdrawal")}
                  </span>
                  <span onClick={() => goto('transfer-cp')} className="btn btn-light btn-sm">
                  {props.t("Transfer")}
                  </span> 
                </div>
              </div>

            </CardBody> 
            </Card>
          </Col>

          <Col xs="6" sm="6" md="3"> 
            <Card className="wallet-box">
            <CardBody> 
              <div className="wallet-header">  
                  <h5>{props.t("total_share")}</h5>
                  <div className="wallet-icon">
                    <img src={icon_wallet}  alt="wallet-icon" width={40}/>
                  </div> 
              </div>
              <div className="wallet-info">  
                <h1>{data.total_share}</h1>  
              </div>
              <div className="wallet-action">  
                <div className="btn-group">  
                  <span onClick={() => goto("market")}  className="btn btn-primary btn-sm">
                  {props.t("Deposit")}
                  </span>{" "} 
                </div>
              </div>

            </CardBody> 
            </Card>
          </Col>

          <Col xs="6" sm="6" md="3"> 
            <Card className="wallet-box">
            <CardBody> 
              <div className="wallet-header">  
                  <h5>{props.t("total_saving")}</h5>
                  <div className="wallet-icon">
                    <img src={icon_wallet}  alt="wallet-icon" width={40}/>
                  </div> 
              </div>
              <div className="wallet-info">  
                <h1>{data.total_saving}</h1>  
              </div>
              <div className="wallet-action">  
                <div className="btn-group">  
                  <span onClick={() => goto("saving")}  className="btn btn-primary btn-sm">
                  {props.t("Saving")}
                  </span>{" "}
                  <span onClick={() => goto('saving-reward')} className="btn btn-light btn-sm">
                  {props.t("Saving Reward")}
                  </span>
                </div>
              </div>

            </CardBody> 
            </Card>
          </Col>

          <Col xs="6" sm="6" md="3"> 
            <Card className="wallet-box">
            <CardBody> 
              <div className="wallet-header">  
                  <h5>{props.t("total_buyback")}</h5>
                  <div className="wallet-icon">
                    <img src={icon_wallet}  alt="wallet-icon" width={40}/>
                  </div> 
              </div>
              <div className="wallet-info">  
                <h1>{data.total_buyback}</h1>  
              </div> 
            </CardBody> 
            </Card>
          </Col> 
          
        </Row> 
    </React.Fragment>
  )
}
export default withTranslation()(CardUser);

CardUser.propTypes = {
  t: PropTypes.any
}; 
