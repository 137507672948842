import React, { useEffect, useState } from "react" 
import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";

//Import Image
import features from "../../assets/images/banner.png"
import features_m from "../../assets/images/banner_m.png"

import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import {post} from '../../helpers/api_helper' 
import icon_notice from "../../assets/images/icon_notice.png"
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import Marquee from "react-fast-marquee";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

const CardWelcome =  (props) => {
  
  const { data, notices } = props;
   
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("I18N_LANGUAGE"));

  useEffect(() => {   
    setCurrentLanguage(localStorage.getItem("I18N_LANGUAGE")) 
  }, [props.t]);

  
  useEffect(() => { 
  }, [notices]); 

  const pagetitle = process.env.REACT_APP_TITLE;

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);

  const handleModalOpen = (notice) => {
    setSelectedNotice(notice);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  }; 
 


  return (
    <React.Fragment>

      <Row>

        <Col md="3" xs="12">
          <Card className="user-box card-box">
            <CardBody> 
                <div className="user-header">
                  <h5>{data.username}</h5>
                  <div className="user-action d-none d-md-block">
                    <i className="mdi mdi-account-circle text-primary h1"></i>
                  </div> 
                  <div className="user-action d-block d-md-none"> 
                    <div className="nav-lang">
                        <LanguageDropdown />
                        <span><i className="fas fa-caret-down"></i></span>
                      </div>
                  </div> 
                </div>
                <div className="user-info">
                  <p className="text-muted mb-1">{data.email}</p>
                  <p className="text-muted mb-0">{props.t("Invite Code")}: {data.inviteCode}</p>
                  <p className="text-muted mb-0">{props.t("KYC Status")}: <span className={data.kyc_code === 1 ? 'text-success' : 'text-danger'}>{props.t(data.kyc_status)}</span></p>
                </div>
            </CardBody> 
          </Card>
        </Col>

        <Col md="3" xs="12" className="order-sm-1">
          <Card className="notice-box card-box">
            <CardBody> 
              <div className="notice-header"> 
                <h4>
                  <img src={icon_notice}  alt="icon-notice" width={20}/>
                  <span>{props.t("Announcement Board")}</span>
                </h4>
              </div>

              <div className="notice-info d-none d-md-block">  
                {notices?.length > 0? (
                  notices.map((item, index) => (
                    <div className="text-muted" key={index}  onClick={() => handleModalOpen(item)}>
                      <p className="mb-1">
                        <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                        {item[`title_${currentLanguage}`] || item.title}
                      </p>
                    </div>
                  ))
                ) : null}
              </div>

              <div className="notice-info d-flex d-md-none"> 
                <Marquee>
                {notices?.length > 0? (
                  notices.map((item, index) => (
                    <div className="text-muted" key={index}  onClick={() => handleModalOpen(item)}> 
                        <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                        {item[`title_${currentLanguage}`] || item.title} 
                    </div>
                  ))
                ) : null}
                </Marquee>
              </div>

            </CardBody> 
          </Card>
        </Col>
          
        <Col md="6" className="order-sm-0">
          <div className="welcome-box card-box">
            <div className="welcome-header">
              <h5>{props.t("Welcome to")} {pagetitle}</h5>
            </div>
            <img src={features} alt="" className="img-fluid d-none d-sm-block" />
            <img src={features_m} alt="" className="img-fluid d-block d-sm-none" />
          </div>
        </Col>
      </Row>
    
 
      <Modal isOpen={modalOpen} toggle={handleModalClose} 
      style={{ zIndex: 8 }}> 
      <ModalBody>
          <div>
            <ModalHeader className="border-bottom-0" toggle={handleModalClose}></ModalHeader>
          </div>
        {selectedNotice && ( 
            <div
              dangerouslySetInnerHTML={{
                __html: props.t(selectedNotice[`content_${currentLanguage}`]),
              }}
            /> 
        )}
      </ModalBody>
    </Modal>

    </React.Fragment>
  )
}

export default withTranslation()(CardWelcome);

CardWelcome.propTypes = {
  t: PropTypes.any
};  
