import React from "react";
import Chart from 'react-apexcharts';

const Line = ({ marketdata }) => { 
  const datetimeData = marketdata.map(item => item.date);
  const priceData = marketdata.map(item => parseFloat(item.total_amount)); // Assuming prices are numerical
  const options = { 
    chart: {
      height: '100%',
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    series: [{
      name: 'Market Data',
      data: priceData
    }],
    xaxis: {
      categories: datetimeData,
      labels: {
        show: false,
      }
    },
    yaxis: {
      show: false,
    },  
  };

  return (
    <div id="chart">
      <Chart options={options} series={[{ data: priceData }]} type="line" />
    </div>
  );
};
export default Line;